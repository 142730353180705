import { useHref } from 'react-router-dom'
import { useResources, AsyncStatus } from '@ossy/cms-client-react'
import { Switch, DelayedRender } from 'design-system'
import { Card } from 'design-system/organisms/card'
import { useEffect } from 'react'

const freeResourcesGridStyles = {
  height: '100%',
  columns: '6 200px',
  columnGap: '16px'
}

export const ResourcesContent = ({ resourcePath = '.' }) => {
  const activeHref = useHref(resourcePath)
  useEffect(() => { console.log('activeHref', activeHref, ' resourcePath', resourcePath) }, [activeHref, resourcePath])
  const { status, resources } = useResources('/publications/images/')
  const tags = resources.flatMap(resource => resource?.content?.tags || [])

  return (
    <Switch on={status}>

      <Switch.Case match={[AsyncStatus.Error]}>
        Something went wrong
      </Switch.Case>

      <Switch.Case match={[AsyncStatus.Loading, AsyncStatus.NotInitialized]}>
        <DelayedRender>
          Loading...
        </DelayedRender>
      </Switch.Case>

      <Switch.Case match={[AsyncStatus.Success]}>

        <main style={{
          height: '100%',
          gridArea: 'main',
          padding: '32px 16px'
        }}>
          <div style={{ margin: '0 auto', display: 'flex', gap: 'var(--space-m)', flexDirection: 'column'  }}>

            <section style={freeResourcesGridStyles}>
              { resources.map(resource => (
                <Card
                  href={`${activeHref}/${resource?.id}`}
                  src={resource?.content?.sizes?.galleryMedium || resource?.content?.src}
                  placeholderSrc={resource?.content?.sizes?.['loader-square-blurred-after'] || resource?.content?.src}
                  style={{ pageBreakInside: 'avoid', cursor: 'pointer' }}
                  key={resources.id}
                />
              ))}
            </section>

          </div>
        </main>

      </Switch.Case>

    </Switch>
  )
}