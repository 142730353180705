import React from 'react'
import { Image } from '../../image/Image.jsx'
import './Card.css'

export const Card = ({
  title,
  subTitle,
  src,
  placeholderSrc,
  imageSrc, //depricated, use src instead
  href,
  target,
  as: Container = 'div',
  ...props
}) => {
  return (
    <Container {...props} className="oskw-image-card">
      <a className="oskw-inner-content" href={href} target={target}>
        <div className="oskw-body">
          <div className="oskw-img-wrapper">
            <Image
              src={src || imageSrc}
              alt={title}
              placeholderSrc={placeholderSrc}
            />
          </div>
          {(!!title || !!subTitle) && (
            <header className="oskw-info">
              { !!title && <h5 className="oskw-title">{title}</h5> }
              { !!subTitle && <span className="oskw-date">{subTitle}</span> }
            </header>
          )}
        </div>
      </a>
    </Container>
  )

}
