import React from 'react'
import { PageSection, View } from 'design-system'
import { Footer } from './Footer.jsx'

export const ContactPage = ({ contact }) => {
    return (
        <PageSection layout="off-center" maxWidth="l" style={{ height: '100%' }}>
             <View
                slot="content"
                surface={contact.surface}
                gap="xxl"
                style={{
                    width: '100%',
                    maxWidth: 'var(--max-width-xl)',
                    padding: 'var(--space-xl) var(--space-l)',
                    borderRadius: 'var(--space-m)',
                }}
            >
                 <Footer
                    columns={contact.columns}
                    placeContent="center space-between"
                    gap="l"
                />
            </View>
        </PageSection>
    )
}