import { ResumeTemplates } from 'connected-components/resume/Resume.template.js'
import { Ossy } from 'themes'

export default {
  workspaceId: "36zDqF0TKZZ5KkJdyg7NH",
  gaId: "G-RM1DRM2DHD",
  theme: Ossy,
  resourceTemplates: [
    ...ResumeTemplates,
  ]
}
