
const seNavigation = [
  { id: 'start-nav', label: 'Start', href: '/' },
  { id: 'services-nav', label: 'Tjänster', href: '/sv/tjanster' },
  { id: 'resources-nav', label: 'Gratis resurser', href: '/se/resources' },
  // { id: 'about-nav', label: 'Om oss', href: '#about' },
  // { id: 'projects-nav', label: 'Projekt', href: '#projects' },
  { id: 'contact-nav', label: 'Kontakt', href: '/sv/kontakta-oss' }
]

export const seContact = [
  { label: 'ossy.se', href: 'https://ossy.se/' },
  { label: 'LinkedIn', href: 'https://www.linkedin.com/company/99854671/' },
  { label: 'yourfriends@ossy.se', href: 'mailto:yourfriends@ossy.se' }
]

export const Swedish = {

  StandardLayout: {
    copyright: 'Copyright @ Oskars Sylwan AB 2023',
    headerMaxWidth: 'large',
    headerActions: [
      { label: 'Kontakt', variant: 'cta', href: '/sv/kontakta-oss' }
    ],
    navigation: seNavigation,
    footer: {
      surface: 'alt-primary',
      columns: [
        {
          maxWidth: 'xs',
          title: 'Oskars Sylwan AB',
          text: `
            Drivna av passion för teknik och innovation,
            är vi dedikerade till att erbjuda lösningar av högsta kvalitet.
            Med en stark tro på att rätt teknik kan förvandla affärslandskapet,
            är vi här för att bli er förlitade partner inom systemutveckling.
          `
        },
        {
          title: 'Navigation',
          list: seNavigation
        },
        {
          title: 'Kontakt',
          list: seContact
        }

      ]
    }
  },

  LandingPage: {

    hero: {
      // title: 'Förstärk er utvecklingsavdelning med experter inom mjukvara',
      title: 'Experter inom mjukvara',
      text: `Vi bygger moderna digitala företagslösningar`,
      actions: [
        { label: 'Tjänster', href: '/sv/tjanster', variant: 'cta' },
        { label: 'Kontakt', href: '/sv/kontakta-oss', variant: 'secondary' },
      ]
    },

    socialProof: {
      title: 'Litad på av',
      columns: [
        {
          title: 'Mikael Schulz',
          logo: 'https://d1yuixo7x29bj4.cloudfront.net/36zDqF0TKZZ5KkJdyg7NH/HFHn21DSCu2f1Gwwzr-xh.png'
        },
        {
          title: 'tepit',
          logo: 'https://d1yuixo7x29bj4.cloudfront.net/36zDqF0TKZZ5KkJdyg7NH/QdwAa6w1mBu8cC0x667Vw.png'
        },
        {
          title: 'Plexus Sanitas',
          logo: 'https://d1yuixo7x29bj4.cloudfront.net/36zDqF0TKZZ5KkJdyg7NH/Of3veKFzLTxAY4qYTmBuW.png'
        },
      ]
    },

    services: [
      {
        title: 'Building something new?',
        icon: 'AddToQueue',
        surface: '#daddd8',
        text: `
          Besides my solid foundation as a Frontend developer I also have experience in build APIs
          and writing infrastructure as code. This means I would be a valuable team member as I can help throughout
          the application stack.
        `
      },
      {
        title: 'Need scalable UI components?',
        icon: 'Dashboard',
        surface: '#ecebe4',
        text: `
          I understand that being flashy isn’t the priority when building application interfaces.
          Rather, making sure the design and components can scale with the application and writing well
          tested code is much more desirable. That’s what I do.
        `
      },
      // {
      //   title: 'Apputveckling med Angular & React',
      //   icon: 'AddToQueue',
      //   text: `
      //     Oavsett om ni siktar på en responsiv hemsida eller komplex webbapplication,
      //     kan vi med vår expertis inom Angular och React leverera lösningar
      //     som är kraftfulla, skalbara, användarvänliga, och estetiskt tilltalande.
      //     Vår erfarenhet täcker allt från initial konceptualisering till lansering och underhåll.
      //   `
      // },
      {
        title: 'Converting functionality?',
        icon: 'Cached',
        surface: '#eef0f2',
        text: `
          Upgrading your tech stack whithout disrupting daily operations is hard.
          Having a person on your team that has done it before is a huge help. I can be that person.
        `
      },
      // {
      //   title: 'Designsystem & Komponentbibliotek',
      //   icon: 'Dashboard',
      //   text: `
      //     I den digitala världen är igenkännbarhet nyckeln till en suverän användarupplevelse.
      //     Vi kan hjälpa dig med att implementera och integrera designsystem som säkerställer
      //     en enhetlig och igenkännbar stil över alla dina digitala gränssnitt.
      //     Tillsammans bygger vi komponentbibliotek som gör det snabbt och enkelt att skapa nya sidor och funktioner,
      //     samtidigt som vi behåller den höga designstandarden.
      //     Låt oss tillsammans skapa digitala upplevelser som dina användare kommer att älska.
      //   `
      // }
    ],

    projects: {
      title: 'Våra projekt',
      text:`
        Under vår karriär har vi haft förmånen att arbeta med en rad professionella projekt
        som har bidragit till att formge vår expertis inom systemutveckling.
        Många av dessa projekt är av konfidentiell natur eller bundna av affärsavtal,
        och kan därför inte visas offentligt här. Men utanför den professionella
        sfären brinner vi för teknik och skapande, vilket reflekteras i de många hobbyprojekt
        vi tar oss an på vår fritid.
        Nedan finner ni ett urval av några professionella projekt vi kan visa
        samt många personliga skapelser som vi gärna delar med oss av.
      `,
      categories: [
        {
          title: 'Hemsidor och designsystem',
          links: [
            [
              {
                label: 'Plexus Sanitas',
                href: 'https://www.plexus-sanitas.com/'
              }
            ],
            [
              {
                label: 'Mikael Shulz',
                href: 'https://www.mikaelschulz.com/'
              }
            ],
            // [
            //   {
            //     label: 'Tepit',
            //     href: 'https://tepit.ossy.se'
            //   }
            // ],
            [
              {
                label: 'Ossy',
                href: 'https://ossy.se/'
              }
            ]
          ]
        },
        {
          title: 'Applicationsutveckling',
          links: [
            [
              {
                label: 'Content management system',
                href: 'https://cms.ossy.se'
              }
            ],
            [
              {
                label: '@ossy/cms-client-react',
                href: 'https://www.npmjs.com/package/@ossy/cms-client-react'
              }
            ],
            [
              {
                label: '@ossy/cli',
                href: 'https://www.npmjs.com/package/@ossy/cli'
              }
            ],
            [
              {
                label: '@ossy/deployment-tools',
                href: 'https://www.npmjs.com/package/@ossy/deployment-tools'
              }
            ]
          ]
        }
      ]
    },

    quickLinks: {
      title: 'Quick links',
      lists: [
        {
          title: 'Applicationsutveckling',
          list: [
            {
              label: 'Content management system',
              href: 'https://cms.ossy.se'
            },
            {
              label: '@ossy/cms-client-react',
              href: 'https://www.npmjs.com/package/@ossy/cms-client-react'
            },
            {
              label: '@ossy/cli',
              href: 'https://www.npmjs.com/package/@ossy/cli'
            },
            {
              label: '@ossy/deployment-tools',
              href: 'https://www.npmjs.com/package/@ossy/deployment-tools'
            }
          ]
        },
        {
          title: 'Hemsidor och designsystem',
          list: [
            {
              label: 'Design system',
              href: 'sv/design-system'
            },
            {
              label: 'Plexus Sanitas',
              href: 'https://www.plexus-sanitas.com/'
            },
            {
              label: 'Mikael Shulz',
              href: 'https://www.mikaelschulz.com/'
            },
            // {
            //   label: 'Tepit',
            //   href: 'https://tepit.ossy.se'
            // },
            {
              label: 'Ossy',
              href: 'https://ossy.se/'
            }
          ]
        }
      ]
    },

    about: {
      surface: 'secondary',
      columns: [
        {
          name: 'Oskars Sylwan',
          role: 'Grundare, Systemutvecklare',
          imgSrc: 'https://d1yuixo7x29bj4.cloudfront.net/36zDqF0TKZZ5KkJdyg7NH/j9SeL8EG7f8vEiJCPPgZa.png',
          text: `
            Frontendutvecklare med fokus på att bygga administrativa system och dashboards i moderna JavaScript ramverk.
            Erfarenhet finns både inom Angular och React och kommer från större bolag, myndigheter, och startups.
            Jag är van vid att ta ansvar för frontenddelar i utvecklingsprocessen vilket inkluderar planering,
            arkitektur, utveckling, sätta riktlinjer, lära ut, och onboarda nya utvecklare.
          `
        }
      ]
    },

    contact: {
      maxWidth: 'm',
      surface: 'primary',
      columns: [
        {
          title: 'Kontakta oss',
          maxWidth: 's',
          text: `
            Om ni är intresserade av att samarbeta, tveka inte att ta kontakt.
            Vi är alltid ivriga att höra om nya projekt eller möjligheter,
            oavsett om det handlar om systemutveckling, konsultation, eller partnerskap.
            Med vår expertis kan vi tillsammans skapa lösningar som driver ert företag framåt.
          `
        },
        {
          list: seContact
        }
      ]
    }

  }

}
