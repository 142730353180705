import React from 'react'
import { css } from 'glamor'
import { Tabs, Button, Title, View, PageSection } from 'design-system'

const sectionStyles = css({
    width: '100%',
    top: '0',
    // top: 'var(--space-s)',
    position: 'sticky',
    zIndex: '10',

    '@media (min-width: 800px)': {
        // top: 'var(--space-s)',
        // padding: '0 var(--space-m)',
    }
})

const styles = css({
  overflowY: 'auto',
  height: '100%',
  width: '100%',
  margin: 0,
  padding: 'var(--space-s)',
  boxSizing: 'border-box',
  display: 'flex',  
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  borderRadius: '32px',
  borderRadius: '16px',

  '& [data-slot="logo"]': {
    display: 'none',
  },

  '@media (min-width: 800px)': {

    gap: 'var(--space-l)',
    padding: 'var(--space-s) var(--space-s)',

    '& [data-slot="logo"]': {
      display: 'flex',
      order: 1,
    },

    '& [data-slot="actions"]': {
      order: 3,
      flexGrow: '1',
      display: 'flex',
      justifyContent: 'flex-end',
    },

    '& [data-slot="navigation"]': {
        order: 2,
      },
  }

})
const MobileHeader = ({
  logo,
  actions = [],
  navigation = [],
  ...props
}) => {

  return (
    <View {...styles}  {...props} surface="primary" layout="row" placeItems="center" placeContent="flex-start">

        <div data-slot="logo">
            { typeof logo !== 'string'
            ? logo
            : (
                <Title variant="logo">
                {logo}
                </Title>
            )}
        </div>

        <div data-slot="actions">
            {actions.map(x => <Button {...x} /> )}
        </div>

        <Tabs
            gap="m"
            data-slot="navigation"
            tabs={navigation}
            variant="primary"
        />

    </View>
  )
}

export const Header = ({
    logo,
    actions,
    navigation,

    // section props
    id= 'header',
    slot='header',
    surfaceAs= "header",
    maxWidth='xl',
    surface="primary",
}) => {
    return (
        <PageSection
            id={id}
            slot={slot}
            surfaceAs={surfaceAs}
            maxWidth={maxWidth}
            // surface={surface}
            className={sectionStyles}
        >
            <MobileHeader
                logo={logo}
                actions={actions}
                navigation={navigation}
            />
        </PageSection>
    )
}