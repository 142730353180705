import React from 'react'
import { Outlet } from 'react-router-dom'
import {
  PageSection,
  Text,
  Page,
  View,
} from 'design-system'
import { Footer } from './Footer.jsx'
import { Header } from './sections/Header.jsx'

export const StandardLayout = ({
  logo,
  headerActions,
  navigation,
  maxWidth = 'xl',
  copyright,
  footer,
}) => {

  return (
    <Page
      surface="base"
      maxWidth={maxWidth}
    >
      <PageSection maxWidth={maxWidth} style={{ padding: 'var(--space-s) 0'}}>

        <View style={{ padding: 'var(--space-m)', textAlign: 'center' }}>
          <Text>We are in <strong>beta</strong> and building as we go. Please be patient with us.</Text>
        </View>

        <View
          surface="primary"
          layout="page"
          gap="m"
          style={{
            minHeight: '100dvh',
            position: 'relative',
            borderRadius: 'var(--space-m)',
            padding: 'var(--space-s)',
            overflow: 'none',
          }}
        >
    
        <Header
          logo={logo}
          actions={headerActions}
          navigation={navigation}
        />

        <PageSection
          id= "outlet"
          slot='content'
          surfaceAs= "main"
          maxWidth='full'
        >
          <Outlet />
        </PageSection>

        <View as="footer" slot="footer" surface="primary" style={{ borderRadius: '16px', margin: '16px' }}>

          <PageSection
            id="footer-section-copyright"
            // surface="primary"
            maxWidth="l"
            style={{ padding: 'var(--space-xl) var(--space-m)' }}
          >
            <Footer columns={footer.columns} />
          </PageSection>

          <PageSection
            id="footer-section-copyright"
            // surface="primary"
            maxWidth="xl"
            style={{ padding: 'var(--space-m)', textAlign: 'center'}}
          >
            <Text variant="small">
              {copyright}
            </Text>
          </PageSection>

        </View>

        </View>
      </PageSection>
    </Page>
  )
}
