import React from 'react'
import { redirect } from 'react-router-dom'
import { PageSection } from 'design-system'
import { Swedish as SE } from './data.js'
import { Logo } from './Logo.jsx'
import { ResourceContentPage } from './ResourceContentPage.jsx'
import { StandardLayout } from './StandardLayout.jsx'
import { LandingPage } from './LandingPage.jsx'
import { Resume } from './Resume.jsx'
import { ContactPage } from './ContactPage.jsx'
import { ServicesPage } from './ServicesPage.jsx'
import { ResourcesPage } from './ResourcesPage.jsx'

export default [
  {
    path: '/',
    element: <StandardLayout {...SE.StandardLayout } logo={<Logo />} style={{ minHeight: '100dvh' }}/>,
    children: [
      {
        index: true,
        loader: () => redirect('/sv'),
      },
      {
        path: '/sv',
        element: <LandingPage hero={SE.LandingPage.hero} socialProof={SE.LandingPage.socialProof} services={SE.LandingPage.services} contact={SE.LandingPage.contact} />
      },
      {
        path: '/en',
        element: <LandingPage hero={SE.LandingPage.hero} socialProof={SE.LandingPage.socialProof} contact={SE.LandingPage.contact} />
      },
      {
        path: '/:languageCode/kontakta-oss',
        element: <ContactPage contact={SE.LandingPage.contact} />
      },
      {
        path: '/:languageCode/tjanster',
        element: <ServicesPage services={SE.LandingPage.services}  />
      },
      {
        path: '/:languageCode/resources',
        element: (
          <PageSection maxWidth="xl">
            <ResourcesPage />
          </PageSection>
        )
      },
      {
        path: '/:languageCode/resources/:resourceId',
        element: <ResourceContentPage />
      },
    ]
  },

  {
    path: '/:languageCode/resume',
    element: <Resume />
  },
  {
    path: '*',
    loader: () => redirect('/en/resume')
  }
]
