import React from 'react'
import { Title, Text, Button, View, PageSection } from 'design-system'
import { css } from 'glamor'

// set background to transparent for mobile view
const sectionStyles = css({
  '@media (max-width: 800px)': {
    '[data-surface]': {
      // '--background': 'transparent',
    }
    
  }
})


const Cover = ({
  title,
  titleMaxWidth = '1100px',
  text,
  actions = [],
}) => (
  <View
    gap="l"
    placeContent='center'
    layout="column"
    justifyContent='center'
    style={{
      height: '100%',
      borderRadius: 'var(--space-m)',
      padding: 'var(--space-xl) var(--space-s)',
    }}
  >

    <View gap="m" alignItems="center">
        <Title as="h1" variant="display" style={{ maxWidth: titleMaxWidth, textWrap: 'balance' }}>
          {title}
        </Title>

        <Text as="h2" variant="m" style={{ maxWidth: '800px' }}>
          {text}
        </Text>
    </View>

    <View gap="m" layout="row" justifyContent="center">
      {actions.map(({ label, ...props }) => (
        <Button {...props} key={label}>
          {label}
        </Button>
      ))}
    </View>

  </View>
)


export const HeroCover = ({
    title,
    titleMaxWidth = '1100px',
    text,
    actions = [],

    // section props
    id= 'hero-cover',
    surfaceAs= "div",
    maxWidth='l',
    surface="decorated",
    ...props
}) => {
    return (
        <PageSection
            id={id}
            surfaceAs={surfaceAs}
            maxWidth={maxWidth}
            surface={surface}
            {...sectionStyles}
            {...props}
        >
            <Cover
              title={title}
              titleMaxWidth={titleMaxWidth}
              text={text}
              actions={actions}
            />
        </PageSection>
    )
}