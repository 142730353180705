import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createHashRouter
} from 'react-router-dom'
import { SDK } from '@ossy/sdk'
import { WorkspaceProvider } from '@ossy/cms-client-react'
import { Theme } from 'design-system'
import { ThemeEditor } from 'connected-components/theme-editor'
import { useNavigate } from './useNavigate.js'

const AppLoader = ({
  workspaceId,
  theme,
  themes,
  gaId,
  apiUrl,
  pages,
  devMode = false
}) => {
  const sdk = SDK.of({ apiUrl, workspaceId })
  const navigate = useNavigate({ pages })

  useEffect(() => {
    if (!gaId) return
    if (!window?.location?.pathname) return
    ReactGA.initialize(gaId)
    ReactGA.send({ hitType: 'pageview', page: window?.location?.pathname })
  }, [gaId])

  const onNavigate = e => {
    if (e.target.tagName !== 'A') return

    const href = e.target.getAttribute('href')
    const hasTarget = e.target.hasAttribute('target')
    const isLocal = (href || '').startsWith('#')
    const isExternalLink = hasTarget || (href || '').includes('.')

    if (!href) return
    if (isExternalLink || isLocal ) return

    e.preventDefault()
    if (gaId) ReactGA.send({ hitType: 'pageview', page: href })
    navigate(href)
  }

  return (
    <Theme theme={theme} themes={themes}>
      <WorkspaceProvider sdk={sdk}>
        <div onClick={onNavigate} style={{ display: 'contents' }}>
          <Outlet />
          { devMode && <ThemeEditor />}
        </div>
      </WorkspaceProvider>
    </Theme>
  )
}

export const App = ({
  workspaceId,
  theme,
  themes,
  routes = [],
  initialEntries = [],
  initialIndex,
  router: _routerType,
  gaId,
  apiUrl,
  devMode = false
}) => {

  const appRoutes = [
    {
      path: '/',
      element: (
        <AppLoader
          gaId={gaId}
          theme={theme}
          themes={themes}
          workspaceId={workspaceId}
          apiUrl={apiUrl}
          devMode={devMode}
          pages={routes}
        />
      ),
      children: routes.map(x => {
        if (x.path !== '/') return x
        if (x.path === '/' && !!x.children) return x
        return { ...x, index: true }
      })
    }
  ]

  const router = _routerType === 'hash'
    ? createHashRouter(appRoutes,  { initialEntries, initialIndex })
    : createBrowserRouter(appRoutes)

  return (
    <RouterProvider router={router} />
  )
}
