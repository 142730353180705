import React from 'react'
import { Title, Text, Button, View, Image } from 'design-system'

export const SocialProof = ({
  socialProof,
}) => (
  <View
    layout="row-wrap"
    placeContent="center"
    gap="xl"
    style={{
      padding: 'var(--space-l) var(--space-s) var(--space-xl)',
      borderRadius: '0 0 var(--space-m) var(--space-m)',
      height: '100%',
      width: '100%',
      maxWidth: 'var(--max-width-m)',
      margin: '0 auto'
  }}>
    
    <View
      gap="l"
      placeItems='center'
      style={{
        width: '100%'
      }}>

      <Text variant="small" style={{ margin: 'var(--space-m) 0', textAlign: 'center' }}>
        {socialProof?.title}
      </Text>
      <View
        layout="row"
        gap="s"
        placeContent="normal space-between"
        placeItems='center'
        style={{
          overflowX: 'auto',
          width: '100%'
        }}
      >

        {
          socialProof?.columns?.map(({ title, logo }) => (
            <Image
              key={title}
              src={logo}
              style={{ width: 'auto', height: '30px', flex: '0 0 auto'  }}
            />
          ))
        }
      </View>

    </View>

  </View>
)
