import React from 'react'
import { useHref, useParams } from 'react-router-dom'
import { useResource, AsyncStatus } from '@ossy/cms-client-react'
import { Switch, View, DelayedRender, Button, Icon, PageSection, Title, Text, Tag } from 'design-system'
import { Card } from 'design-system/organisms/card'
import { css } from 'glamor'

const resourceContainerStyles = css({
  height: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  wrap: 'wrap',
  gap: 'var(--space-m)',
  // backgroundColor: 'tomato',

  '@media(min-width: 768px)': {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gridTemplateRows: 'auto',  
  }
})

const textView = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'var(--space-m)',
  padding: 'var(--space-s)',
  maxWidth: '500px',
  '@media(min-width: 768px)': {
    padding: 'var(--space-m)',
    order: 2
  
  }
})


export const ResourceContentPage = (props) => {
  const { resourceId } = useParams()
  const { status, resource } = useResource(resourceId)
  const href = useHref()

  return (
    <View {...props} slot="content" style={{ flexDirection: 'column', wrap: 'no-wrap',  height: '100%' }}>

      <PageSection maxWidth="xl">

        <View style={{ padding: '0 ar(--space-l)'}} >

          <View layout="row" style={{ justifyContent: 'space-between', borderRadius: 'var(--space-l)', padding: 'var(--space-xxs)', background: 'transparent' }}>
            <Button variant="neutral" href={href.replace(`/${resourceId}`, '')} style={{ padding: 'var(--space-xs)', borderRadius: '50%' }}>
              <Icon name="Previous" />
            </Button>
          </View>

        </View>

      </PageSection>

      <View layout="off-center" style={{ flexGrow: '1' }}>
        <View slot="content" style={{ padding: 'var(--space-m) var(--space-m) var(--space-xxl)'}}>
          <Switch on={status}>

            <Switch.Case match={[AsyncStatus.Error]}>
              Something went wrong
            </Switch.Case>

            <Switch.Case match={[AsyncStatus.Loading, AsyncStatus.NotInitialized]}>
              <DelayedRender>
                Loading...
              </DelayedRender>
            </Switch.Case>

            <Switch.Case match={[AsyncStatus.Success]}>

              <PageSection maxWidth="l">
                <div {...resourceContainerStyles}>

                  <div {...textView}>
                    <Title>{resource?.name}</Title>
                    <Text>{resource?.content?.description}</Text>
                    <View layout="row" gap="m">
                      <Button
                        variant="cta"
                        target="_blank"
                        href={resource?.content?.src}
                        download={resource?.name}
                        suffix="software-download"
                      >Download
                      </Button>
                    </View>
                    <div style={{ display: 'flex', gap: 'var(--space-xs)', flexWrap: 'wrap' }}>
                      {resource?.content?.tags?.map(tag => <Tag key={tag}>{tag}</Tag>)}
                    </div>

                  </div>

                    {
                      !!resource && (
                        <Card
                          onClick={() => { }}
                          src={resource?.content?.sizes?.galleryLarge || resource?.content?.src}
                          placeholderSrc={resource?.content?.sizes?.['loader-square-blurred-after'] || resource?.content?.src}
                          style={{ pageBreakInside: 'avoid', order: 1 }}
                        />
                      )
                    }

                </div>
              </PageSection>

            </Switch.Case>

          </Switch>
        </View>
      </View>

  </View>
  )
}

// {
//   !!resource && (
//     <Button
//       as="a"
//       href="https://d1yuixo7x29bj4.cloudfront.net/36zDqF0TKZZ5KkJdyg7NH/MRHX4agbiHC9p3N9_Wzfd.png"
//       download
//       variant="cta"
//     >
//       Download
//     </Button>
//   )
// }
