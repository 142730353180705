import React from 'react'
import { css } from 'glamor'
import { PageSection, Title, View, Card, Icon, Text } from 'design-system'
import { SocialProof } from './SocialProof.jsx'
import { ResourcesContent } from './ResourcesContent.jsx'
import { HeroCover } from './sections/HeroCover.jsx'

const cover = css({
  gap: 'var(--space-m)',
  height: '100%',
  maxHeight: '300px',

'@media (min-width: 1950px)': {
    maxHeight: '450px'
}
})


const rounded = css({
    borderRadius: 'var(--space-m)',
    overflow: 'hidden',
})

export const LandingPage = ({ hero, socialProof, services }) => {
    return (
        <PageSection maxWidth="xl" gap="l">

            <HeroCover {...hero} {...rounded} {...cover} />

            <View surface="primary" inset="l" {...rounded} >
                <SocialProof socialProof={socialProof} />
            </View>

            <View
                surface="primary"
                gap="xxl"
                inset="l"
                style={{
                    width: '100%',
                    margin: '0 auto',
                }}
            >

                <View
                    gap="m"
                    placeItems='center'
                    style={{
                        maxWidth: 'var(--max-width-xl)',
                        margin: '0 auto',
                    }}
                >
                    <Title>Tjänster</Title>
                    <Text maxWidth="xs" style={{ textAlign: 'center'}}>
                        Med konslter du kan lite på hjälper vi dig med dina utvecklingsbehov.
                    </Text>
                </View>

                <View
                    layout="row"
                    placeItems="stretch"
                    gap="m"
                    style={{
                        overflowX: 'auto',
                        height: '100%',
                        margin: '0 auto',
                        maxWidth: 'var(--max-width-l)',
                    }}
                >
                    {
                        services.map(({ title, text, icon, surface }) => (
                            <Card
                                placeItems="flex-start"
                                key={title}
                                style={{
                                    minWidth: '300px',
                                    border: '1px solid var(--separator)',
                                    borderRadius: 'var(--space-m)',
                                    background: surface
                            }}>
                                <View surface="secondary" style={{
                                    padding: 'var(--space-s)',
                                    borderRadius: 'var(--space-s)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: 'var(--space-xl)',
                                    background: 'transparent',
                                }}>
                                    { icon && <Icon name={icon} size="l" />}
                                </View>
                                <View style={{ flexGrow: '1' }}>
                                    <Title variant="secondary" style={{ marginBottom: 'var(--space-s)' }}>
                                        {title}
                                    </Title>
                                    <Text variant="small" style={{ maxWidth: '600px' }}>
                                        {text}
                                    </Text>
                                </View>
                            </Card>
                        ))
                    }
                    </View>
            </View>

            <View
                gap="xxl"
                style={{
                    maxWidth: 'var(--max-width-xl)',
                    margin: 'var(--space-xxl) auto',
                }}
            >

                <View
                    gap="m"
                    placeItems='center'
                    style={{
                        maxWidth: 'var(--max-width-xl)',
                        margin: '0 auto',
                    }}
                >
                    <Title>Gratis resurser</Title>
                    <Text maxWidth="xs" style={{ textAlign: 'center'}}>
                        Inte redo att ta kontakt? Ladda ner våra gratis resurser för att komma igång.
                    </Text>
                </View>

                <ResourcesContent resourcePath="/sv/resources" />
            </View>

        </PageSection>
    )
}