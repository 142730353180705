import { useHref } from 'react-router-dom'
import { useResources, AsyncStatus } from '@ossy/cms-client-react'
import { Tag, Title, Text, Switch, DelayedRender, View } from 'design-system'
import { Card } from 'design-system/organisms/card'

const freeResourcesGridStyles = {
  height: '100%',
  columns: '6 200px',
  columnGap: '16px'
}

export const ResourcesPage= ({ resourcePath = '.' }) => {
  const activeHref = useHref(resourcePath)
  const { status, resources } = useResources('/publications/images/')
  const tags = resources.flatMap(resource => resource?.content?.tags || [])
  const tagsCount = tags.reduce((acc, t) => ({ ...acc, [t]: (acc?.[t] ?? 0) + 1 }), {})

  return (
    <Switch on={status}>

      <Switch.Case match={[AsyncStatus.Error]}>
        Something went wrong
      </Switch.Case>

      <Switch.Case match={[AsyncStatus.Loading, AsyncStatus.NotInitialized]}>
        <DelayedRender>
          Loading...
        </DelayedRender>
      </Switch.Case>

      <Switch.Case match={[AsyncStatus.Success]}>

        <main style={{
          height: '100%',
          gridArea: 'main',
          padding: '32px 16px 0'
        }}>
          <View gap="l" style={{ margin: '0 auto' }}>

            <View gap="m">
              <Title>Free Resources</Title>
              <Text style={{ maxWidth: '900px'}}>
                We believe in providing value at every step of your journey with us.
                That's why we've curated a collection of free resources.
                Whether you're looking to enhance your creative projects or simply seeking inspiration,
                explore the gallery below to discover valuable assets that align with your vision.
                We hope you find them useful, and we're here if you have any questions!
              </Text>
            </View>
            
            <section style={freeResourcesGridStyles}>
              { resources.map(resource => (
                  <Card
                    href={`${activeHref}/${resource?.id}`}
                    src={resource?.content?.sizes?.galleryMedium || resource?.content?.src}
                    placeholderSrc={resource?.content?.sizes?.['loader-square-blurred-after'] || resource?.content?.src}
                    style={{ pageBreakInside: 'avoid', cursor: 'pointer' }}
                    key={resources.id}
                  />
              ))}
            </section>

            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 'var(--space-xxs)', marginTop: '24px' }}>
              {Object.entries(tagsCount).map(([tag, count]) => <Tag key={tag}>{count} {tag}</Tag>)}
            </div>
          </View>


        </main>

      </Switch.Case>

    </Switch>
  )
}