import React from 'react'
import { PageSection, View, Text, Title, Card, Icon } from 'design-system'

export const ServicesPage = ({ services }) => {
    return (
        <PageSection layout="off-center" maxWidth="l" style={{ height: '100%' }}>
            <View
                slot="content"
                gap="xxl"
                style={{
                    width: '100%',
                    maxWidth: 'var(--max-width-l)',
                    margin: 'var(--space-xxl) auto',
                }}
            >

                <View
                    gap="m"
                    placeItems='center'
                    style={{
                        maxWidth: 'var(--max-width-xl)',
                        margin: '0 auto',
                    }}
                >
                    <Title>Tjänster</Title>
                    <Text maxWidth="xs" style={{ textAlign: 'center'}}>
                        Med konslter du kan lite på hjälper vi dig med dina utvecklingsbehov.
                    </Text>
                </View>

                <View
                    layout="row"
                    placeItems="stretch"
                    gap="m"
                    style={{ overflowX: 'auto', height: '100%' }}
                >
                    {
                        services.map(({ title, text, icon, surface }) => (
                            <Card
                                placeItems="flex-start"
                                key={title}
                                style={{
                                    minWidth: '300px',
                                    border: '1px solid var(--separator)',
                                    borderRadius: 'var(--space-m)',
                                    background: surface
                            }}>
                                <View surface="secondary" style={{
                                    padding: 'var(--space-s)',
                                    borderRadius: 'var(--space-s)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: 'var(--space-xl)',
                                    background: 'transparent',
                                }}>
                                    { icon && <Icon name={icon} size="l" />}
                                </View>
                                <View style={{ flexGrow: '1' }}>
                                    <Title variant="secondary" style={{ marginBottom: 'var(--space-s)' }}>
                                        {title}
                                    </Title>
                                    <Text variant="small" style={{ maxWidth: '600px' }}>
                                        {text}
                                    </Text>
                                </View>
                            </Card>
                        ))
                    }
                    </View>
            </View>
        </PageSection>
    )
}