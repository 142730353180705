import { useParams, useNavigate } from 'react-router-dom'
import { Resume as ConnectedResume } from 'connected-components'

export const Resume = () => {
    const { languageCode = 'en' } = useParams()
    const navigate = useNavigate()
    const path = `/${languageCode}/Consultants/Oskars Sylwan/`
  
    const actions = [
      {
        variant: 'neutral',
        label: languageCode.toUpperCase(),
        contextMenu: [
          { label: 'EN', onClick: () => navigate('/en/resume') },
          { label: 'SV', onClick: () => navigate('/sv/resume') },
        ]
      },
      {
        variant: 'cta',
        label: 'View in Google Drive',
        target: '_blank',
        href: languageCode === 'sv'
          ? 'https://docs.google.com/document/d/1JyjzYMRFkXZT8_mgybinQAJHNNlfzFgHcrQsUnq4knk/edit?usp=share_link'
          : 'https://docs.google.com/document/d/1N6jWLjghIhNOGSspS8YUBhH2pykkieijbB1IVWb606g/edit?usp=share_link'
      }
    ]
  
    return <ConnectedResume path={path} actions={actions} />
  }