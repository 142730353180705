import { useNavigate as useNavigateInternal } from 'react-router-dom'

export const useNavigate = ({ pages: _pages }) => {
    const pages = new Map(_pages.map(page => [page.id, page]))
    const _navigate = useNavigateInternal()

    const navigate = (href) => {
        if (!href.startsWith('@')) return _navigate(href)

        const [_, pageId] = href.split('@')
        const page = pages.get(pageId)
        const path = page?.path || '/not-found'
        _navigate(path)

        // todo: build link from link template and insert params
        // take into account the current locale
    }

    return navigate
}

// const appendSlash = string => string[string.length - 1] === '/'
//   ? string : `${string}/`

// const prependSlash = string => string[0] === '/'
//   ? string : `/${string}`

// const padWithSlash = string => appendSlash(prependSlash(string))

// export const getUrl = ({ location, pages }) => {
//     // TODO: this needs tests because I don't think it works right
//     const activePathname = location.pathname === '' ? '/' : padWithSlash(location.pathname)
//     const activePathnameParts = activePathname.split('/').filter(x => !!x)

//     const registeredPageRoutes = pages.map(page => padWithSlash(page.location.replace('/pages', '')))
//     const exactRouteMatch = registeredPageRoutes.find(route => route === activePathname)

//     if (!!exactRouteMatch) {
//         return exactRouteMatch
//     }

//     const dynamicRouteMatch = registeredPageRoutes.find(route => (
//     Predicates.byLength(activePathnameParts.length)(route) && Predicates.byParamPlaceHolders(activePathname)(route)
//     ))

//     console.log('dynamicRouteMatch', dynamicRouteMatch)

//     return dynamicRouteMatch
//   }